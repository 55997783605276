// import Bootstrap and its default variables
@import 'bootstrap/scss/bootstrap';

/* stylelint-disable selector-max-type */
body {
  overflow: hidden;
}

@media print {
  @page {
    size: letter portrait;
    margin: 0.5cm;
  }

  @page :left {
    margin: 0.5cm;
  }

  @page :right {
    margin: 0.8cm;
  }

  tr,
  div.galleryRow {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    margin-bottom: 20px;

    img {
      max-height: calc(48vh - 60px);
    }
  }
}
/* stylelint-enable selector-max-type */

.stackIcon {
  display: block;
  width: calc($basic-padding-margin-spacing * 3 / 2);
  margin: auto;
  margin-bottom: $icon-tabs-icon-margin-bottom;
}

.tabTitle {
  margin-bottom: $tab-title-margin-bottom;
  color: $secondary-600;

  &:not(:first-child) {
    margin-top: $basic-padding-margin-spacing * 2;
  }
}

.tabDescription {
  font-size: $font-size-sm;
  font-weight: $semi-bold;
  color: $secondary;
}

.singleLineTruncate {
  @include single-line-ellipsis($white-space: nowrap);
}

.galleryContainer {
  @include gallery-make-container;

  position: relative;
  height: 100%;
  padding-top: 4px;
  padding-bottom: 20px;
  overflow-y: auto;
}

.galleryCol {
  margin-bottom: 1px;

  @include make-col-ready;

  @include gallery-media-breakpoint-up(sm) {
    @include make-col(12);
  }

  @include gallery-media-breakpoint-up(md) {
    @include make-col(6);
  }

  @include gallery-media-breakpoint-up(lg) {
    @include make-col(4);
  }

  @include gallery-media-breakpoint-up(xl) {
    @include make-col(3);
  }

  @include gallery-media-breakpoint-up(xxl) {
    @include make-col(2);
  }
}

/* stylelint-disable selector-class-pattern, no-descending-specificity */
.mapbox-control-styles button {
  display: flex !important;
  align-items: center;
  width: auto !important;
  padding: 0 8px !important;
}

.react-tabs {
  -webkit-tap-highlight-color: transparent;

  .large {
    padding: 0;
    margin: 0 0 10px;
    border-bottom: 1px solid #eaeaea;

    .react-tabs__tab {
      padding: $icon-tabs-margin-y $icon-tabs-list-margin-x-large
        $icon-tabs-margin-y 0;
    }
  }
}

.react-tabs__tab-list {
  padding: 0;
  margin: 0 0 $icon-tabs-margin-y;
  border-bottom: 1px solid $secondary-100;
}

.react-tabs__tab {
  position: relative;
  bottom: -1px;
  display: inline-block;
  padding: $icon-tabs-margin-y $icon-tabs-list-margin-x $icon-tabs-margin-y 0;
  font-size: $font-size-tiny;
  color: $secondary-50;
  text-transform: uppercase;
  list-style: none;
  cursor: pointer;
  border-bottom: none;

  &:not(:first-child) {
    padding-left: $icon-tabs-list-margin-x;
  }

  &:focus {
    outline: none;
  }

  .stackIcon {
    &.fillIcon {
      * {
        fill: $white;
        stroke: none;
      }
    }

    &.strokeIcon {
      fill: none;
      stroke: $white;
    }
  }

  &:hover {
    color: $tabs-hover;

    .stackIcon {
      &.fillIcon {
        * {
          fill: $tabs-hover;
          stroke: none;
        }
      }

      &.strokeIcon {
        fill: none;
        stroke: $tabs-hover;
      }
    }
  }

  &.react-tabs__tab--selected {
    color: $secondary-600;
    background: transparent;

    .stackIcon {
      &.fillIcon {
        * {
          fill: $secondary-600;
          stroke: none;
        }
      }

      &.strokeIcon {
        * {
          fill: none;
          stroke: $secondary-600;
        }
      }
    }
  }

  &.react-tabs__tab--disabled,
  &.react-tabs__tab--disabled:hover {
    color: $tabs-disabled;
    cursor: not-allowed;

    .stackIcon {
      &.fillIcon {
        * {
          fill: $tabs-disabled;
          stroke: none;
        }
      }

      &.strokeIcon {
        fill: none;
        stroke: $tabs-disabled;
      }
    }
  }
}

.react-datepicker__header--custom {
  background-color: #fff !important;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

// Overwrite bootstrap style
.list-group {
  margin-top: $item-list-margin-y * 2;
}

.list-group-item {
  min-height: $item-list-item-height;
  padding: $item-list-text-padding-y $item-list-text-padding-x;
  font-size: $font-size-sm;
  border-radius: 0;

  &:not(:first-child) {
    margin-top: $item-list-margin-y * 2;
  }

  .hidden {
    display: none;
  }

  &:hover {
    .hidden {
      display: inline-block;
    }
  }
}

.buttonsGroup {
  margin-top: $buttons-group-margin-top;
}

// tree
.treeList {
  width: 100%;

  .list-group {
    margin: $item-list-margin-y 0;
    background-color: #f3f3f3;
    border: none;
  }

  .list-group:hover {
    background-color: #f3f3f3c0;
  }
}

.treeList,
.treeList ul {
  position: relative;
}

.treeList li {
  position: relative;
  padding-top: 5px;
}

.treeList ul {
  padding-left: 40px;
  list-style: none;
}

.treeList ul:first-child {
  padding: 0 $basic-padding-margin-spacing $basic-padding-margin-spacing;
}

.treeList li::before,
.treeList li::after {
  position: absolute;
  left: -24px;
  content: '';
}

.treeList .treeListGroup {
  margin-top: 0;
}

.treeList .treeListChild::before {
  top: 26px;
  width: 24px;
  height: 0;
  border-top: 1px solid #aeaeae;
}

.treeList .treeListChild::after {
  top: 0;
  width: 1px;
  height: 100%;
  border-left: 1px solid #aeaeae;
}

.treeList .treeListParent::before {
  border: none;
}

.treeList .treeListParent::after {
  border: none;
}

.treeList ul > li:last-child::after {
  height: 27px;
}

.treeListItem {
  padding: 5px 0 0;
}

.treeListParent {
  .groupOptionContent {
    padding: 0;
    margin-bottom: 0;
  }
}

.groupOption:not(:first-child) {
  position: relative;
  margin-top: 24px;
}

.panelGroupOption {
  margin-bottom: 20px;

  .list-group {
    background-color: transparent;
  }

  .list-group:hover {
    background-color: transparent;
  }

  .treeList ul:first-child {
    padding: 0;
  }
}

.groupOptionTitle {
  margin-bottom: $basic-padding-margin-spacing;
  font-size: $font-size-default;
  text-transform: capitalize;

  &:last-child {
    margin-bottom: 0;
  }
}

.form-label {
  margin-bottom: $form-field-margin-y;
  font-size: $font-size-sm;
  font-weight: $semi-bold;
  color: $dark;

  &.is-invalid {
    color: $red-500;
  }

  &.is-large {
    font-size: 13px;
  }
}

.form-label-description {
  margin-bottom: 4px;
  font-size: $font-size-sm;
  font-weight: $regular;
  color: $secondary;

  &.is-large {
    margin-bottom: 8px;
    font-size: 13px;
    line-height: 18px;
  }
}

.is-invalid {
  .form-control:not(.skip-errors) {
    background-color: $red-50;
    border-color: $red-500;
  }
}

.form-control {
  padding: 7.5px 12px;
  margin-right: 30px;
  font-size: $font-size-sm;
  color: $dark;
  background-color: #fff;
  border: 1px solid $secondary-100;
  border-radius: $basic-border-radius;
  outline: none;
  box-shadow: none;

  &.isRegularWidget {
    padding-top: 9px;
    padding-bottom: 9px;
    font-size: 14px;
    line-height: 18px;
  }

  &.isLargeWidget {
    padding: 14px;
    font-size: 16px;
    line-height: 1.3;
  }

  &:active,
  &:focus {
    border-color: $primary;
    outline: none;
    box-shadow: none;
  }

  &.unset {
    width: unset;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.form-switch {
  color: #5f5f5f;

  .form-check-input {
    height: 1.25em;

    &:focus {
      outline: none;
    }
  }

  .form-check-label {
    font-size: $font-size-sm;
  }
}

.alert {
  font-size: $font-size-default;
}

@media (width >= 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}

.groupOptionContent {
  padding: $basic-padding-margin-spacing;
  background-color: $secondary-50;

  .custom-col-timer {
    width: 248px;
    padding-right: 0;
  }

  .custom-col {
    width: 28px;
    padding-right: 0;
    line-height: 28px;
  }

  .form-control {
    background-color: white;
  }

  &:not(.is-jsonForm) {
    .form-control,
    .custom-select,
    .input-group-text {
      border: none;
    }
  }

  .groupOptionLabel:not(.is-large) {
    margin-bottom: calc($basic-padding-margin-spacing / 2);
    font-size: $font-size-default;

    &:not(:first-child) {
      margin-top: $basic-padding-margin-spacing;
    }
  }

  .groupOptionDescription {
    font-size: $font-size-sm;
    color: $secondary;
  }
}

.disabled {
  .groupOptionContent {
    position: relative;

    .disableOverlay {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      height: 100%;
      cursor: not-allowed;
      background-color: rgba(255 255 255 / 50%);
    }
  }
}

.customSelect {
  width: 100%;
  font-size: 12px;

  .select__single-value {
    .checkmark-icon,
    .thumbnail {
      display: none;
    }

    .option-label {
      // padding-left: 20px !important;
      // margin-left: -9px !important;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.svgButton {
  color: $secondary;
  background: none;
  border: none;

  &:hover {
    color: $secondary-600;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.btn {
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }
}

input:focus {
  outline: none;
}

input:-internal-autofill-selected {
  background: none;
}

textarea.form-control {
  line-height: 1.5;
}

.cursorPointer {
  cursor: pointer;
}

.mapControlBottomLeft {
  position: absolute;
  z-index: 11;
  float: left;
  margin: 0 0 10px 10px;
}

.mapControlTopLeft {
  position: absolute;
  z-index: 11;
  float: left;
  margin: 10px 0 0 10px;
}

.mapControlGroup > button {
  box-sizing: border-box;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
}

// animation
/* stylelint-disable */

.actionSlideIn-enter {
  opacity: 0;
  transform: translateX(100%);
}

.actionSlideIn-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
  transition-timing-function: ease-in;
  transform: translateX(0%);
}

.actionSlideIn-exit {
  opacity: 1;
}

.actionSlideIn-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateX(150%);
}
.accToggleableContent-enter {
  max-height: 0;
}
.accToggleableContent-enter-active {
  overflow: hidden;
  max-height: 1000px;
  transition: max-height 1s ease-in-out;
}
.accToggleableContent-enter-done {
  transform: none;
}
.accToggleableContent-exit {
  max-height: 1000px;
}
.accToggleableContent-exit-active {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.45s cubic-bezier(0, 1, 0, 1);
}
.fadeIn-enter {
  opacity: 0;
}

.fadeIn-enter-active {
  opacity: 1;
  transition: opacity 300ms;
  transition-timing-function: ease-in;
}

.fadeIn-exit {
  opacity: 1;
}

.fadeIn-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.panelSlideInRight-enter {
  opacity: 0;
  transform: translateX(100%);
}

.panelSlideInRight-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.panelSlideInRight-exit {
  opacity: 1;
}

.panelSlideInRight-exit-active {
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 300ms, transform 300ms;
}

.panelSlideInLeft-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.panelSlideInLeft-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.panelSlideInLeft-exit {
  opacity: 1;
}

.panelSlideInLeft-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms, transform 300ms;
}

// spin animation

.spin {
  -webkit-animation-name: spin;
  -webkit-animation-duration: 4000ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 4000ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 4000ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;

  animation-name: spin;
  animation-duration: 4000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rstcustom__rowTitle {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* stylelint-enable */

a:hover {
  text-decoration: none;
}

.message {
  font-size: 12px;
}

.bladeToolbox {
  position: absolute;
  top: calc(-20px - #{$title-editor-height});
  right: 0;
}

input[type='time']::-webkit-calendar-picker-indicator {
  display: none;
}

/* Works on Firefox */
* {
  scrollbar-color: $scroll-thumb $scroll-track;
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */

*::-webkit-scrollbar {
  width: $scrollbar-width;
  height: $scrollbar-width;
}

*::-webkit-scrollbar-track {
  background: $scroll-track;
}

*::-webkit-scrollbar-thumb {
  background-color: $scroll-thumb;
  border-radius: 20px;
}

.asterisk {
  font-size: 10px;
  font-weight: $semi-bold;
  color: $red-500;

  &.is-large {
    font-size: 13px;
  }
}

.semibold {
  font-weight: $semi-bold;
}
